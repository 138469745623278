<template>
  <div class="app-notice-container">
    <div class="header">
      <el-button type="primary" @click="showCreateDialog = true">
        添加记录
      </el-button>
    </div>

    <el-radio-group v-model="currentType" @change="typeChange">
      <el-radio-button label="全部"></el-radio-button>
      <el-radio-button label="启用中"></el-radio-button>
      <el-radio-button label="未启用"></el-radio-button>
    </el-radio-group>

    <el-table :data="applicationList.items" border stripe>
      <el-table-column
        v-for="col in columns"
        :prop="col.id"
        :key="col.id"
        :label="col.label"
        :width="col.width"
        :formatter="col.formatter"
      ></el-table-column>
      <el-table-column label="图片">
        <template slot-scope="scope">
          <img :src="scope.row.image" width="80" />
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleEdit(scope.row)">
            编辑
          </el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      :current-page="currentPage"
      :page-sizes="[30, 10, 50]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="applicationList.total"
      background
      @size-change="sizeChange"
      @current-change="currentChange"
    ></el-pagination>

    <el-dialog
      :title="isEdit ? '修改编辑推荐' : '添加编辑推荐'"
      :visible.sync="showCreateDialog"
      width="36%"
      @close="onClose"
    >
      <el-form ref="form" :model="postForm" label-width="120px">
        <el-form-item label="大标题" required>
          <el-input v-model="postForm.title"></el-input>
        </el-form-item>
        <el-form-item label="副标题" required>
          <el-input
            v-model="postForm.subtitle"
            type="textarea"
            :rows="8"
          ></el-input>
        </el-form-item>
        <el-form-item label="图片" required>
          <el-upload
            action="https://www.ddpurse.com/uploadImage"
            list-type="picture-card"
            :limit="1"
            :file-list="picList"
            :on-success="uploadSuccess"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item v-if="!isEdit" label="语言环境" required>
          <el-radio-group v-model="postForm.language">
            <el-radio-button label="zh">中文</el-radio-button>
            <el-radio-button label="en">英文</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="点击图片响应" required>
          <el-radio-group v-model="postForm.type">
            <el-radio-button label="1">跳转应用</el-radio-button>
            <el-radio-button label="3">URL（webview）</el-radio-button>
            <el-radio-button label="2">URL（唤起浏览器）</el-radio-button>
            <el-radio-button label="0">无</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="跳转参数" required>
          <el-input
            v-model="postForm.link"
            placeholder="URL或应用编号"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否启用" required>
          <el-switch
            v-model="postForm.status"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="showCreateDialog = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import {
  addApplication,
  fetchApplicationList,
  postApplication,
  deleteApplication,
} from "@/api/application";

const LANG_MAP = {
  zh: "中文",
  en: "英文",
};

const TYPE_MAP = {
  1: "跳转应用",
  3: "URL（webview）",
  2: "URL（唤起浏览器）",
  0: "无",
};

const STATUS_MAP = {
  全部: "",
  启用中: 1,
  未启用: 0,
};

export default {
  data() {
    return {
      columns: [
        { id: "id", label: "编号", width: 80 },
        { id: "title", label: "大标题" },
        { id: "subtitle", label: "副标题" },
        {
          id: "type",
          label: "跳转类型",
          formatter: (row) => {
            return TYPE_MAP[row.type];
          },
        },
        {
          id: "language",
          label: "语言环境",
          formatter: (row) => {
            return LANG_MAP[row.language];
          },
        },
        { id: "link", label: "链接内容" },
        {
          id: "status",
          label: "启用状态",
          width: 80,
          formatter: (row) => {
            switch (+row.status) {
              case 0:
                return <el-tag type="success">未启用</el-tag>;
              case 1:
                return <el-tag type="warning">启用中</el-tag>;
              default:
                return row.status;
            }
          },
        },
        {
          id: "created_at",
          label: "创建时间",
        },
      ],
      postForm: {
        title: "",
        subtitle: "",
        type: "",
        language: "zh",
        link: "",
        status: "",
        image: "",
      },
      isEdit: false,
      showCreateDialog: false,
      currentPage: 1,
      pageSize: 30,
      currentType: "全部",
      applicationList: {
        items: [],
        count: 0,
      },
    };
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    picList() {
      if (this.postForm.image) {
        return [
          {
            name: "url",
            url: this.postForm.image,
          },
        ];
      }
      return [];
    },
  },
  methods: {
    fetchData() {
      let query = {};
      query = {
        page: this.currentPage,
        page_size: this.pageSize,
      };
      if (this.currentType != "全部") {
        query["status"] = STATUS_MAP[this.currentType];
      }
      fetchApplicationList(query).then((data) => {
        if (+data.data.code === -1) {
          return;
        }
        const applicationList = data.data.data || [];
        if (applicationList) {
          this.applicationList = {
            items: applicationList.data_list,
            count: applicationList.total_num,
          };
        }
      });
    },
    uploadSuccess(data) {
      if (+data.code) {
        this.$message({
          message: "上传失败",
          duration: 3000,
          type: "waring",
        });
      } else {
        this.postForm.image = data.data;
      }
    },
    sizeChange(size) {
      this.pageSize = size;
      this.fetchData();
    },
    currentChange(page) {
      this.currentPage = page;
      this.fetchData();
    },
    typeChange(val) {
      this.currentType = val;
      this.fetchData();
    },
    handleEdit(data) {
      this.showCreateDialog = true;
      this.isEdit = true;
      this.postForm = {
        id: data.id,
        title: data.title,
        subtitle: data.subtitle,
        type: data.type,
        language: data.language,
        link: data.link,
        status: data.status,
        image: data.image,
      };
    },

    onClose() {
      this.postForm = {
        title: "",
        subtitle: "",
        type: "",
        language: "zh",
        link: "",
        status: "",
        image: "",
      };
      this.showCreateDialog = false;
      this.isEdit = false;
    },
    onSubmit() {
      if (Object.values(this.postForm).includes("")) {
        return this.$message({
          message: "请填写必填项",
          duration: 3000,
          type: "error",
        });
      }
      if (this.isEdit) {
        postApplication(this.postForm).then((data) => {
          if (+data.data.code) {
            this.$message({
              message: data.data.msg,
              duration: 3000,
              type: "error",
            });
            this.handleSuccess();
          } else {
            this.$message({
              message: data.data.msg,
              duration: 3000,
              type: "success",
            });
            this.handleSuccess();
          }
        });
      } else {
        addApplication(this.postForm).then((data) => {
          if (+data.data.code) {
            this.$message({
              message: data.data.msg,
              duration: 3000,
              type: "error",
            });
            this.handleSuccess();
          } else {
            this.$message({
              message: data.data.msg,
              duration: 3000,
              type: "success",
            });
            this.handleSuccess();
          }
        });
      }
    },
    handleSuccess() {
      this.showCreateDialog = false;
      this.postForm = {};
      this.isEdit = false;
      this.fetchData();
    },

    handleDelete(row) {
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteApplication({ id: row.id }).then(() => {
          this.$message({
            message: "删除成功",
            duration: 3000,
            type: "success",
          });
          this.fetchData();
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.app-notice-container {
  margin: 20px;

  .header {
    margin-bottom: 20px;
    text-align: right;
    margin-right: 20px;
    font-size: 14px;

    > .label {
      margin-right: 12px;
    }
  }
}
</style>
