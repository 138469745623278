import axios from "axios";
import config from "@/configs/index";

const PRE_URL = config.url + "/admin_wallet";

// 获取ApplicationList
export const fetchApplicationList = (query) => {
  return axios({
    url: PRE_URL + "/api/admin/recommendedApplication",
    method: "get",
    params: query,
  });
};

// 新建Application
export const addApplication = (params) => {
  return axios({
    url: PRE_URL + "/api/admin/recommendedApplication",
    method: "post",
    data: params,
  });
};

export const postApplication = (params) => {
  return axios({
    url: PRE_URL + "/api/admin/recommendedApplication/" + params.id,
    method: "put",
    data: params,
  });
};

// 删除Application
export const deleteApplication = (params) => {
  return axios({
    url: PRE_URL + "/api/admin/recommendedApplication/" + params.id,
    method: "delete",
    data: params,
  });
};
